<template>
  <div>{{ id }}</div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "order",
  props: {
    id: Number,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("sea.pages.order.title");
    });
  },
});
</script>
